.fas,
.far,
.fa {
  vertical-align: middle;
  font-size: 1em;
}

.fa-play,
.fa-forward,
.fa-check,
.fa-external-link-alt,
.fa-file-download {
  color: #4caf50;
}

.fa-stop,
.fa-pause,
.fa-times {
  color: #f44336;
}
