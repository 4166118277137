.message-center {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: var(--message-z-index);
  pointer-events: none;
}

.message {
  font-family: "Roboto Mono", monospace;
  font-size: 1.25em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: var(--color-light-gray);
  width: var(--toolbar-width);
  padding: var(--less-padding);
  border: 2px solid var(--color-gray);
  margin: var(--normal-margin);
  pointer-events: auto;
}

.message-content {
  text-align: start;
}

.message--icon {
  font-size: 1.5em;
  margin-left: 1.5em;
}

.message--icon:hover {
  cursor: pointer;
}

.message--success {
  background-color: var(--success-color);
  border: 2px solid var(--success-color-dark);
}

.message--error {
  background-color: var(--danger-color);
  border: 2px solid var(--danger-color-dark);
}
