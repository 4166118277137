.javascript-vm {
  grid-area: JSVM;
  background-color: #ccc;
  justify-self: flex-end;
}

.javascript-vm-controls {
  min-width: 400px;
}

.javascript-vm-controls--stop-button,
.javascript-vm-controls--stop-button:hover {
  color: #dc3545;
}

.javascript-vm-controls--run-button,
.javascript-vm-controls--run-button:hover,
.javascript-vm-controls--start-button,
.javascript-vm-controls--start-button:hover {
  color: #28a745;
}

.javascript-vm-controls--step-button {
  color: #007bff;
}

.javascript-vm-controls--execution-speed-toggle {
  margin: 10px;
}
