.list {
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 0;
}

.list--item {
  font-family: "Roboto Mono", "Roboto", Arial, Helvetica, sans-serif;
  width: 100%;
  padding-left: var(--normal-padding);
  padding-right: var(--normal-padding);
  padding-top: var(--less-padding);
  padding-bottom: var(--less-padding);

  /* Indent content but make border full width */
  margin-left: 0;
  margin-right: -1em;
  border-bottom: 1px solid #cfcfcf;
}

.list--item__title {
  font-weight: bold;
}
