html,
body,
#root,
.app-container {
  height: 100%;
  margin: 0;

  display: flex;
  flex-direction: column;
  overflow: hidden;
}
