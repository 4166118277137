:root {
  --toolbar-transform: translate(
    0px,
    calc(
      var(--toolbar-visible-height) - var(--toolbar-height) +
        var(--toolbar-hover-height)
    )
  );
}

.toolbar-container {
  position: fixed;
  /* The top of the toolbar should align with the bottom of the header */
  top: var(--header-height);

  display: flex;
  width: var(--toolbar-width);
  z-index: var(--toolbar-z-index);
  margin-left: 0;
  margin-right: calc((100% - var(--toolbar-width)) / 2);
  flex-direction: column;
  align-items: left;
  pointer-events: none;
}

@media only screen and (max-width: 1500px) {
  .toolbar-container {
    width: calc(2 * var(--toolbar-width));
  }
}

.toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  pointer-events: auto;

  width: 100%;
  height: var(--toolbar-height);
  z-index: var(--toolbar-z-index);
}

.toolbar:focus {
  transform: translate(0px, 0px);
}

/* The following CSS is to allow the toolbar to expand when the user tabs throughout the page - it requires extra work
   to behave properly however so its commented out for now. */
/* .toolbar:focus-within {
  transform: translate(0px, 0px);
} */

.toolbar .btn {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
  /* Buttons should expand to take up the entire space equally */
  width: 100%;
  height: 100%;

  /* remove margin as there is no hover border */
  margin-bottom: 0px;

  color: var(--text-color-light);
  border-bottom: 2px solid black;
  background-color: var(--highlight-color);
  transition-duration: 60ms;
}

.toolbar .btn:hover {
  /* border-bottom: 2px solid black; */
  /* border-right: 2px solid black; */
  border-right: 2px solid var(--highlight-color-lightest);
  transform: scale(1.1, 1.1);
}

.toolbar .btn:last-child:hover {
  border-bottom: 2px solid black;
  border-right: none;
  transform: scale(1.2, 1.2);
}

.toolbar .btn:focus {
  background-color: var(--highlight-color-dark);
}

/* Left Border of Toolbar */
.toolbar .btn:first-child {
  /* Left Corner */
  border-radius: 0 0 0 var(--border-radius);
}

.toolbar .btn:last-child {
  /* Right Corner */
  border-radius: 0 0 var(--border-radius) 0;
}

/* Dashed Border Between Each Button */
.toolbar .btn + .btn {
  border-left: 2px solid var(--highlight-color-lightest);
}
