.blockly-workspace {
  /* position set to absolute so this element dont't contribute to overall height calculation */
  position: absolute;
}

.blockly-workspace.executing {
  cursor: not-allowed;
  pointer-events: none;
}

.blockly-workspace.executing:before {
  /* We want to display the not allowed ico */
  pointer-events: auto;
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #d8ff002e;
  z-index: 1;
}
