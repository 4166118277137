.draggable-container {
  position: absolute;
  background: transparent;
  border-radius: var(--border-radius);
  padding: 4px;
  z-index: var(--gamepad-z-index);
}

.draggable-container:hover {
  margin: 0px;
  cursor: move;
  border: 2px dotted black;
}
