:root {
  --card-width: 21em;
  --card-height: 25em;
}

.card {
  display: flex;
  flex-direction: column;

  background-color: var(--highlight-color);
  box-shadow: -6px 6px 0px 2px var(--highlight-color-dark);
  color: var(--text-color-light);

  border-radius: var(--border-radius-rounder);

  width: var(--card-width);
  height: var(--card-height);
  margin-right: calc(var(--more-margin) * 2);
  padding: var(--normal-padding);

  transition: transform 0.25s ease, box-shadow 0.25s ease;
}

.card--title-and-status {
  display: flex;
  flex-direction: row;
  border-color: black;
}

.card--thumbs {
  border-color: black;
  height: "4em";
  margin-left: 10px;
  align-self: center;
}
.card:hover {
  transform: translate(-2px, 2px);
  box-shadow: -4px 4px 0px -2px var(--highlight-color-darkest);
  background-color: var(--highlight-color-light);
}

.card--image {
  /* width: 100%; */
  height: 128px;
  /*border: 2px solid var(--primary-color-light);*/
}
.card--image-container {
  padding: 0.1em;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--color-light-gray);
}

.card--title {
  /* Place Holder */
}

.card--body {
  background-color: var(--highlight-color-dark);
  padding: 0.25em;
  padding-left: 0.5em;
  border-radius: 10px;
  box-shadow: inset 0px 0px 0px 2px var(--highlight-color-darker);
  flex: 1;
  font-size: 24px;
}
