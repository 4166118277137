.title {
  text-align: center;
  margin: 0;
  padding: var(--less-padding);
  margin-bottom: var(--more-margin);
}

.title--divider {
  border-bottom: 2px solid black;
}
