.header {
  display: flex;
  flex-direction: row;
  align-items: center;

  color: var(--text-color-light);
  background-color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color-dark);

  height: var(--header-height);

  z-index: var(--header-z-index);
}

.header--program-title {
  margin: 0;
  padding: 0;
}

.header--program-title-icon {
  padding-right: 0.5em;
  /* allign the icon horizontally with the text */
  margin-top: -0.2em;
}

.header--user-controls {
  margin-left: auto;
  color: var(--text-color-light);
}

.header--feedback {
  margin-left: auto;
  padding: 0.5em 1em;
  margin-right: 1em;
}

.header--feedback:hover {
  background-color: var(--primary-color-dark);
}

.brand-image-container {
  height: 100%;
}

.brand-image-link {
  display: block;
  height: 100%;
}

.brand {
  padding: 0.7em 2.9em;
  /* Brand Icon must be at least 1.875 (30px) */
  /* height: 90%; */
}

.timer {
  text-align: center;
  /* padding: 0em 1em; */
}

.timer span {
  font-family: monospace;
  font-size: 2em;
}

.timer hr {
  border-color: var(--primary-color);
  margin-block: 0.1em;
}
